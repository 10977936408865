import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, switchMap } from "rxjs/operators";
import { throwError, Subscription } from "rxjs";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem, MessageService } from 'primeng/api';
import { canadaZipPattern, countries, months, otherZipPattern, provinces, states, usZipCodeFiveOrNineDigits, emailPattern } from 'src/app/config/constants';
import { ApiService } from 'src/app/core/services/api/api.service';

@Component({
	selector: 'app-quick-pay',
	templateUrl: './quick-pay.component.html',
    styleUrls: ['./quick-pay.component.less']
})
export class QuickPayComponent implements OnInit
{
	public breadcrumbItems: MenuItem[] = [
		{ label: 'Quick Pay' },
	];
	public breadcrumbHome: MenuItem = { icon: 'pi pi-home', routerLink: '/' };
	public stepItems = [
		{ label: 'Find' },
		{ label: 'Payment Info' },
		{ label: 'Verification' },
		{ label: 'Confirmation' }
	];
	public activeIndex = 0;
	public findPolicyForm!: FormGroup;
	public paymentInfoForm!: FormGroup;
	public sendEmailForm!: FormGroup;
	public isLoading: boolean = false;
	public sendEmailLoading: boolean = false;
	public countries = countries;
	public statesOrProvinces = states;
	public months = months;
	public creditCardYears: any = [];
	public stateProvinceLabel: string = "State";
	public cityLabel: string = "City";
	public zipPostalLabel: string = "ZIP Code";
	public zipPostalMask: RegExp | any = "00000-0000";
	public zipPostalMaxLength: number = 10;
	public paymentTitle: string = "";
	public paymentDesc: string = "";
	public policyData: any;
	public maskedCreditCard: string = "";
	public hiddenAccountNumber: string = "";
	public activeTab = "card";
	public displaySendEmailModal: boolean = false;
	public confirmationPageError: boolean = false;
	public confirmationPageErrorMsg: string = "";
	public cyberSourceReturnedData: any;
	public todaysDate: Date = new Date();
	public paymentConfigData: any;
	public paymentReqObj: any;
	public signature: any;

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private messageService: MessageService,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void
	{
		this.findPolicyForm = this.fb.group({
			lastName: [this.activatedRoute.snapshot.queryParams?.ln || "", [Validators.required]],
			policyNumber: [this.activatedRoute.snapshot.queryParams?.pn || "", [Validators.required]],
			zipCode: [this.activatedRoute.snapshot.queryParams?.zip || "", [Validators.required, Validators.minLength(5), Validators.maxLength(5),
				Validators.pattern(usZipCodeFiveOrNineDigits)]]
		}, { updateOn: 'submit' });
		this.paymentInfoForm = this.fb.group({
			cardPayment: this.fb.group({
				sameAsBillingAddress: false,
				firstName: ["", [Validators.required]],
				lastName: ["", [Validators.required]],
				streetAddress: ["", [Validators.required]],
				country: ["US", [Validators.required]],
				city: ["", [Validators.required]],
				state: ["", [Validators.required]],
				zip: ["", [Validators.required, Validators.pattern(usZipCodeFiveOrNineDigits)]],
				cardNumber: ["", [Validators.required, Validators.minLength(16), Validators.maxLength(16), this.validateCardVisaOrMastercard.bind(this)]],
				expMonth: ["", [Validators.required]],
				expYear: ["", [Validators.required]],
				cvv: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
			}, { updateOn: 'submit' }),
			checkPayment: this.fb.group({
				routingNum: ["", [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
				accountNum: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(17)]]
			}, { updateOn: 'submit' })
		});
		this.checkPaymentForm.disable();

		this.creditCardYears = this.getCreditCardYears();

		this.sendEmailForm = this.fb.group({
			emailAddress: ["", [Validators.required, Validators.pattern(emailPattern)]]
		}, { updateOn: 'submit' });

		this.paymentHeaderChange(0);

		if (this.activatedRoute.snapshot.queryParams.hasOwnProperty("confirmation")) // returning from cybersource
		{
			this.setupConfirmationPage();
        }
	}

	public findMyBill()
	{
		if (!this.isLoading && this.findPolicyForm.valid)
		{
			this.isLoading = true;
			this.apiService.getPolicyData(this.findPolicyForm.get("policyNumber")?.value,
			this.findPolicyForm.get("lastName")?.value?.trim(), this.findPolicyForm.get("zipCode")?.value).subscribe((response) => {
				this.policyData = response;
				this.paymentHeaderChange(1);
                this.isLoading = false;
			}, (error) => {
				this.isLoading = false;
				this.messageService.add({severity:'error', summary:'Error', detail: error["Message"] || "Could not retrieve policy."});
			});
		}
	}

	public goToVerify()
	{
		if (this.paymentInfoForm.valid && !this.isCCExpireDateInvalid())
		{
			if (this.activeTab === 'card')
			{
				this.maskedCreditCard = this.cardPaymentForm.get("cardNumber")?.value?.replace(/.(?=.{4})/g, 'X');
			}
			else 
			{
				this.hiddenAccountNumber = this.checkPaymentForm.get("accountNum")?.value.replace(/.(?=.{4})/g, 'X');
            }
            this.paymentHeaderChange(2);
		}
	}

	public processPayment()
	{
		if (!this.isLoading)
        {
            this.isLoading = true;

            this.apiService.getPaymentFields().pipe(
                switchMap((data) =>
                {
                    this.paymentConfigData = data;
                    const paymentParams = this.buildPaymentReqObj(true);
                    const paymentParmsFormatted = Object.keys(paymentParams).map(key => `${key}=${paymentParams[key]}`).join(',');
                    return this.apiService.getSignature(paymentParmsFormatted);
                }),
                switchMap((signature) =>
                {
                    this.signature = signature;

                    // log payment sent
                    const eventInfo = {
                        EventType: "Payment Submitted",
                        SourceSystem: "FFI Public",
                        Company: "Florida Family Insurance",
                        Interface: "Wells Fargo (CC Payments) - CyberSource",
                        TransactionType: "Premium Payment",
                        FunctionalArea: "Accounting",
                        PolicyId: this.policyData.PolicyId,
                        EventDateTime: this.paymentConfigData["SignedDateTime"],
                        TransactionId: this.paymentConfigData["TransactionUuid"],
                        EventXML: {
                            PaymentInfo: {
                                PaymentAmount: this.policyData.NextAmtDue,
                                PaymentMethod: this.activeTab
                            }
                        },
                        Operation: "External",
                        LoggingLevel: "Info"
                    };
                    return this.apiService.logEvent(eventInfo);
                })
            ).subscribe(() =>
            {
                sessionStorage.setItem("policy", JSON.stringify(this.policyData));
                this.paymentReqObj = this.buildPaymentReqObj(false);
            }, (error) =>
            {
                this.isLoading = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Could not process payment." });
            });	
		}
	}

	 /**
     * @desc builds params for either signature api or cybersource redirect
     * @param queryParams if payment info is needed (signature api)
     */
	private buildPaymentReqObj(queryParams: boolean): Object
	{
		const signedFieldNames = "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,merchant_secure_data1,merchant_secure_data2,merchant_secure_data3,merchant_defined_data5,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,ignore_cvn";
		const unsignedFieldNames = this.activeTab === 'card' ? "card_type,card_number,card_expiry_date,card_cvn" : "echeck_account_type,echeck_sec_code,echeck_routing_number,echeck_account_number";
		let reqObj: any = {};

		if (this.activeTab === 'card')
        {
            reqObj.firstName = this.cardPaymentForm.get("firstName")?.value;
			reqObj.lastName = this.cardPaymentForm.get("lastName")?.value;

			const tempCardNumber: string = this.cardPaymentForm.get("cardNumber")?.value;

			if (tempCardNumber.charAt(0) === '4') //Visa
			{
				reqObj.cardType = "001";
			} else
			{
				reqObj.cardType = "002";
			}

			// Populate card expiration date in the formatted needed by cybersource mm-yyyy
			reqObj.cardExpireDate = this.cardPaymentForm.get("expMonth")?.value + "-" + this.cardPaymentForm.get("expYear")?.value;
			reqObj.stateVal = this.cardPaymentForm.get("state")?.value || "";
			reqObj.billingAddr1 = this.cardPaymentForm.get("streetAddress")?.value;
			reqObj.city = this.cardPaymentForm.get("city")?.value;
			reqObj.country = this.cardPaymentForm.get("country")?.value;
			reqObj.postalCode = this.cardPaymentForm.get("zip")?.value || "";
		}
		else
        {
			reqObj.firstName = this.policyData.PolicyHolderFirstName == "" ? this.policyData.AdditionalPolicyHolderFirstName : this.policyData.PolicyHolderFirstName;
			reqObj.lastName = this.policyData.PolicyholderLastName == "" ? this.policyData.AdditionalPolicyHolderLastName : this.policyData.PolicyholderLastName;
			reqObj.stateVal = this.policyData.PolicyPropertyAddress.State;
			reqObj.billingAddr1 = this.policyData.PolicyPropertyAddress.Address1;
			reqObj.city = this.policyData.PolicyPropertyAddress.City;
			reqObj.country = this.policyData.PolicyPropertyAddress.CountryAbbreviation;
			reqObj.postalCode = this.policyData.PolicyPropertyAddress.PostalCode;
		}

		const paymentObj: any = {
			'access_key': this.paymentConfigData["AccessKey"],
			'profile_id': this.paymentConfigData["ProfileId"],
			'transaction_uuid': this.paymentConfigData["TransactionUuid"],
			'signed_field_names': signedFieldNames,
			'unsigned_field_names': unsignedFieldNames,
			'signed_date_time': this.paymentConfigData["SignedDateTime"],
			'locale': "en",
			'merchant_secure_data1': this.policyData.PolicyId,
			'merchant_secure_data2': "",
			'merchant_secure_data3': "",
			'merchant_defined_data5': "P",
			'transaction_type': "sale,create_payment_token",
			'reference_number': this.policyData.PolicyNumber,
			'amount': this.policyData.NextAmtDue,
			'currency': "USD",
            'payment_method': this.activeTab,
            'bill_to_forename': reqObj.firstName,
			'bill_to_surname': reqObj.lastName,
			'bill_to_email': "Helpdesk@floridafamily.com",
			'bill_to_phone': "239-555-0101",
			'bill_to_address_line1': reqObj.billingAddr1,
			'bill_to_address_line2': "",
			'bill_to_address_city': reqObj.city,
			'bill_to_address_state': reqObj.stateVal,
			'bill_to_address_country': reqObj.country,
			'bill_to_address_postal_code': reqObj.postalCode,
			'ignore_cvn': true
		}

		if (!queryParams)
        {
            paymentObj['card_type'] = reqObj.cardType || "";
            paymentObj['card_number'] = this.cardPaymentForm.get("cardNumber")?.value || "";
            paymentObj['card_expiry_date'] = reqObj.cardExpireDate || "";
            paymentObj['card_cvn'] = this.cardPaymentForm.get("cvv")?.value || "";
            paymentObj['echeck_account_type'] = "c";
            paymentObj['echeck_sec_code'] = "WEB";
            paymentObj['echeck_routing_number'] = this.checkPaymentForm.get("routingNum")?.value || "";
            paymentObj['echeck_account_number'] = this.checkPaymentForm.get("accountNum")?.value || "";
            paymentObj['signature'] = this.signature;
		}

		return paymentObj;
	}

	public setupConfirmationPage()
	{
		this.cyberSourceReturnedData = (window as any)["paymentApprovalResponse"];

        if (this.cyberSourceReturnedData && this.cyberSourceReturnedData?.Decision && sessionStorage.getItem("policy"))
        {
			this.activeTab = this.cyberSourceReturnedData?.PaymentMethod;

            if (this.activeTab === "echeck")
            {
                this.checkPaymentForm.enable();
                this.cardPaymentForm.disable();
			}

			this.paymentHeaderChange(3);
			this.policyData = JSON.parse(sessionStorage.getItem("policy") || "");
			sessionStorage.removeItem("policy");

			if (this.cyberSourceReturnedData?.Decision !== 'ACCEPT')
            {
				const reasonCode = this.cyberSourceReturnedData?.ReasonCode;
				const field = this.cyberSourceReturnedData?.InvalidFields;
				this.confirmationPageError = true;

				switch (reasonCode)
				{
					case "102":
						switch (field)
						{
							case "card_expiry_date":
								this.confirmationPageErrorMsg = "Please verify that the expiration date you entered is correct.";
								break;
							case "card_number":
							default:
								this.confirmationPageErrorMsg = "Please verify that the account number you entered is correct.";
								break;
						}
						break;
					case "211":
						this.confirmationPageErrorMsg = "Please verify that the 3-digit CVV/security code you entered is correct.";
						break;
					case "231":
						this.confirmationPageErrorMsg = "Please verify that the account number you entered is correct.";
						break;
					case "388":
						this.confirmationPageErrorMsg = "Please verify that the routing number you entered is correct.";
						break;
					default:
						this.confirmationPageErrorMsg = "";
						break;
				}
			}

            this.logConfirmationResponse();
		}
	}

	public sendEmail()
    {
        this.sendEmailForm.markAllAsTouched();

        if (!this.sendEmailLoading && this.sendEmailForm.valid)
		{
            this.sendEmailLoading = true;
            this.apiService.sendEmail(this.cyberSourceReturnedData, this.sendEmailForm.get("emailAddress")?.value, "Florida Family - Payment Receipt").subscribe(() =>
            {
                this.displaySendEmailModal = false;
                this.sendEmailLoading = false;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: "Your Email Confirmation was successfully sent." });
                this.sendEmailForm.controls.emailAddress.reset();
            }, (error) =>
            {
                this.sendEmailLoading = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Could not send email." });
            });
		}
	}

	public printDoc()
	{
		window.print();
	}

	public fillBillingAddress(event: any)
	{
		if (event["checked"]) {
			this.cardPaymentForm.get("country")?.setValue(this.policyData.PolicyPropertyAddress.CountryAbbreviation);
			this.setCountry();
			this.cardPaymentForm.patchValue({
				firstName: this.policyData.PolicyHolderFirstName,
				lastName: this.policyData.PolicyholderLastName,
				streetAddress: this.policyData.PolicyPropertyAddress.Address1,
				city: this.policyData.PolicyPropertyAddress.City,
				state: this.policyData.PolicyPropertyAddress.State,
				zip: this.policyData.PolicyPropertyAddress.PostalCode,
			});
		}
		else
		{
			this.cardPaymentForm.get("country")?.setValue("US");
			this.setCountry();
			this.cardPaymentForm.patchValue({
				firstName: "",
				lastName: "",
				streetAddress: "",
				city: "",
				state: "",
				zip: "",
			});
		}
	}
	
	public clearSameAsBilling()
	{
		this.cardPaymentForm.get("sameAsBillingAddress")?.setValue(false);
	}

	public goBack()
	{
		if (!this.isLoading && this.activeIndex !== 0) // dont allow back click while loading
		{
			this.activeIndex -= 1;
			this.paymentHeaderChange(this.activeIndex)
		}
	}

	public goBackFromConfirmError()
	{
		this.paymentHeaderChange(1);
		this.confirmationPageError = false;
		this.confirmationPageErrorMsg = "";

		this.findPolicyForm.patchValue({
			lastName: this.policyData.PolicyholderLastName || "",
			policyNumber: this.policyData.PolicyNumber || "",
			zipCode: this.policyData.PolicyPropertyAddress.PostalCode.substring(0, 5) || ""
		});
		this.findPolicyForm.markAllAsTouched();

        if (this.activeTab === "card")
		{
            this.cardPaymentForm.get("country")?.setValue(this.cyberSourceReturnedData?.BillToAddressCountry || "US");
			this.setCountry();
			this.cardPaymentForm.patchValue({
                firstName: this.cyberSourceReturnedData?.BillToFirstName || "",
                lastName: this.cyberSourceReturnedData?.BillToLastName || "",
                streetAddress: this.cyberSourceReturnedData?.BillToAddressLine1 || "",
                city: this.cyberSourceReturnedData?.BillToAddressCity || "",
                state: this.cyberSourceReturnedData?.BillToAddressState || "",
                zip: this.cyberSourceReturnedData?.BillToAddressPostalCode || "",
			});
		}
	}

	public resetForm(event: NgbNavChangeEvent)
	{
		if (event.nextId === "card")
		{
			this.cardPaymentForm.enable();
			this.cardPaymentForm.reset();
			this.cardPaymentForm.get("country")?.setValue("US");
			this.setCountry();
			this.checkPaymentForm.disable();
		}
		else if (event.nextId === "echeck")
		{
			this.checkPaymentForm.enable();
			this.checkPaymentForm.reset();
			this.cardPaymentForm.disable();
		}
		this.cdr.detectChanges();
	}

	private getCreditCardYears(): Array<any>
	{
		let yearArr: any = [];
		const todaysDate = new Date();
		for (let i = 0; i < 10; i++)
		{
			const newYearToAdd = todaysDate.getFullYear() + i;
			yearArr.push({ label: newYearToAdd.toString(), value: newYearToAdd });
		}
		return yearArr;
	}

	public setCountry(event?)
	{
		// update: onSubmit won't update form based on country until submitting without this
		if (event) this.cardPaymentForm.get("country")?.setValue(event.value);

		if (this.cardPaymentForm.get("country")?.value === "CA") 
		{
			this.cityLabel = "City";
			this.stateProvinceLabel = "Province";
			this.zipPostalLabel = "Postal Code";
			this.zipPostalMaxLength = 7;
			this.zipPostalMask = "S0S 0S0";
			this.statesOrProvinces = provinces;
			this.cardPaymentForm.controls.zip.enable();
			this.cardPaymentForm.controls.zip.reset();
			this.cardPaymentForm.controls.zip.setValidators([Validators.required, Validators.pattern(canadaZipPattern)]);
			this.cardPaymentForm.controls.state.reset();
			this.cardPaymentForm.controls.state.enable();
		}
		else if (this.cardPaymentForm.get("country")?.value !== "US")
		{
			this.cityLabel = "City/State/Postal Code";
			this.cardPaymentForm.controls.zip.reset();
			this.cardPaymentForm.controls.zip.disable();
			this.cardPaymentForm.controls.state.reset();
			this.cardPaymentForm.controls.state.disable();
		}
		else
		{
			this.cityLabel = "City";
			this.stateProvinceLabel = "State";
			this.zipPostalLabel = "ZIP Code";
			this.zipPostalMaxLength = 10;
			this.zipPostalMask = "00000-0000";
			this.statesOrProvinces = states;
			this.cardPaymentForm.controls.zip.enable();
			this.cardPaymentForm.controls.zip.reset();
			this.cardPaymentForm.controls.zip.setValidators([Validators.required, Validators.pattern(usZipCodeFiveOrNineDigits)]);
			this.cardPaymentForm.controls.state.reset();
			this.cardPaymentForm.controls.state.enable();
		}
	}

	public paymentHeaderChange(index: number)
	{
		switch (index)
		{
			case 0:
				this.activeIndex = 0;
				this.paymentTitle = "Find My Bill";
				this.paymentDesc = `Welcome to the Florida Family Quick Pay. To get started, please enter the following information from your invoice into the required fields, and then select the "Find My Bill" button.`;
				break;
			case 1:
				this.activeIndex = 1;
				this.paymentTitle = "Payment Info";
				this.paymentDesc = `Choose to pay by VISA, MasterCard or a check, enter your payment information, and then click the "Continue to Verification" button.`;
				break;
			case 2:
				this.activeIndex = 2;
				this.paymentTitle = "Verification";
				this.paymentDesc = `Please verify the information you entered, and then click the "Process Payment" button.`;
				break;
			case 3:
				this.activeIndex = 3;
                this.paymentTitle = "Confirmation";
                const refNum = this.activeTab === "echeck" ? this.cyberSourceReturnedData?.eCheckDebitReferenceNumber : this.cyberSourceReturnedData?.AuthorizedTransactionReferenceNumber;
                this.paymentDesc = refNum ? "Your confirmation code is " + refNum : ""; 
				break;
		}
	}

	// reactive form validator
	public validateCardVisaOrMastercard()
	{
		if (!this.paymentInfoForm || !this.cardPaymentForm || !this.cardPaymentForm.get("cardNumber") || !this.cardPaymentForm.get("cardNumber")?.value)
        {
            return null;
        }
		return this.cardPaymentForm.get("cardNumber")?.value[0] === "4" || this.cardPaymentForm.get("cardNumber")?.value[0] === "5" 
			? null : { notValidCard: true };
	}

	public isCCExpireDateInvalid(): boolean
	{
		if (this.activeTab === "card" && this.cardPaymentForm.get("expYear")?.valid && this.cardPaymentForm.get("expMonth")?.valid)
		{
			const today: Date = new Date();
			const minDate: Date = new Date(today.getFullYear(), today.getMonth());
			const ccExpireDate: Date = new Date(this.cardPaymentForm.get("expYear")?.value, this.cardPaymentForm.get("expMonth")?.value - 1);
			return ccExpireDate < minDate;
		}
		return false;
    }

    private logConfirmationResponse()
    {
        const eventInfo = {
            EventType: this.getEventType(this.cyberSourceReturnedData.Decision),
            SourceSystem: "FFI Public",
            Company: "Florida Family Insurance",
            Interface: "Wells Fargo (CC Payments) - CyberSource",
            TransactionType: "Premium Payment",
            FunctionalArea: "Accounting",
            PolicyId: this.cyberSourceReturnedData.PolicyId,
            TransactionId: this.cyberSourceReturnedData.TransactionUuid,
            EventXML: this.getEventXml(this.cyberSourceReturnedData.ReasonCode),
            Operation: "External",
            LoggingLevel: this.getLogLevel(this.cyberSourceReturnedData.Decision)
        };
        this.apiService.logEvent(eventInfo).subscribe(() => { });
    }

    private getEventType(paymentDecision: string)
    {
        switch (paymentDecision)
        {
            case "ACCEPT":
                return "Payment Approved";
            case "DECLINE":
                return "Payment Declined";
            case "REVIEW":
                return "Payment In Review";
            case "ERROR":
                return "Payment Submission Failure";
            case "CANCEL":
                return "Payment Canceled";
            case "TIMEOUT":
                return "Payment Gateway Down";
            default:
                return "Unknown";
        }
    }

    private getEventXml(paymentReasonCode: string): { PaymentResponse: { Response_Code; Response_Message; Auth_Code?; Invalid_Fields?} }
    {
        switch (paymentReasonCode)
        {
            case "100":
                return {
                    PaymentResponse:
                    {
                        Auth_Code: this.cyberSourceReturnedData.PaymentMethod === "card"
                            ? this.cyberSourceReturnedData.AuthorizedTransactionReferenceNumber : this.cyberSourceReturnedData.eCheckDebitReferenceNumber,
                        Response_Code: this.cyberSourceReturnedData.ReasonCode,
                        Response_Message: this.cyberSourceReturnedData.Decision,
                    }
                };
            case "102":
                return {
                    PaymentResponse:
                    {
                        Response_Code: this.cyberSourceReturnedData.ReasonCode,
                        Response_Message: this.cyberSourceReturnedData.Decision,
                        Invalid_Fields: this.cyberSourceReturnedData.InvalidFields
                    }
                };
            default:
                return {
                    PaymentResponse:
                    {
                        Response_Code: this.cyberSourceReturnedData.ReasonCode,
                        Response_Message: this.cyberSourceReturnedData.Decision,
                    }
                };
        }
    }

    private getLogLevel(paymentDecision: string)
    {
        switch (paymentDecision)
        {
            case "TIMEOUT":
                return "Error";
            default:
                return "Info";
        }
    }

	public get cardPaymentForm(): FormGroup
	{
		return this.paymentInfoForm.controls.cardPayment as FormGroup;
	}

	public get checkPaymentForm(): FormGroup
	{
		return this.paymentInfoForm.controls.checkPayment as FormGroup;
	}

	public get checkboxFormControl(): FormControl
	{
		return this.cardPaymentForm.controls.sameAsBillingAddress as FormControl;
	}

}
